<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <!-- Forgot Password v1 -->
            <b-card class="mb-0">
                <b-link class="brand-logo">
                    <!-- logo -->
                    <vuexy-logo />

                    <h2 class="brand-text text-primary ml-1">
                        {{ $t('appName') }}
                    </h2>
                </b-link>

                <b-card-title class="mb-1">
                    {{ $t('forgotPassword.title') }}
                </b-card-title>
                <b-card-text class="mb-2">
                    {{ $t('forgotPassword.description') }}
                </b-card-text>

                <!-- form -->
                <validation-observer ref="simpleRules">
                    <b-form
                        class="auth-forgot-password-form mt-2"
                        @submit.prevent="validationForm"
                    >
                        <!-- email -->
                        <b-form-group
                            :label="$t('ui.email')"
                            label-for="forgot-password-email"
                        >
                            <validation-provider
                                #default="{ errors }"
                                :name="$t('ui.email')"
                                rules="required|email|max:255"
                            >
                                <b-form-input
                                    id="forgot-password-email"
                                    v-model="login"
                                    :state="errors.length > 0 ? false : null"
                                    name="forgot-password-email"
                                    placeholder="john@example.com"
                                    maxlength="255"
                                />
                                <small class="text-danger">{{
                                    errors[0]
                                }}</small>
                            </validation-provider>
                        </b-form-group>

                        <!-- submit button -->
                        <b-button variant="primary" block type="submit">
                            {{ $t('forgotPassword.next') }}
                        </b-button>
                    </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                    <b-link :to="{ name: 'login', params: { enteredLogin } }">
                        <feather-icon icon="ChevronLeftIcon" />
                        {{ $t('forgotPassword.previous') }}
                    </b-link>
                </b-card-text>
            </b-card>
            <!-- /Forgot Password v1 -->
        </div>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
} from 'bootstrap-vue';
import { required, email, max } from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        VuexyLogo,
        BCard,
        BLink,
        BCardText,
        BCardTitle,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        ValidationProvider,
        ValidationObserver,
    },

    props: {
        enteredLogin: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            login: this.enteredLogin,
            // validation
            required,
            email,
            max,
        };
    },

    methods: {
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('toasts.resetPassword'),
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
